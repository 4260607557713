<template>
  <VaNavbar class="app-layout-navbar py-2 bg-primary-custom">
    <template #left>
      <div class="left">
        <RouterLink to="/" aria-label="Visit home page">
          <VuesticLogo />
        </RouterLink>
      </div>
    </template>
    <template #right>
      <AppNavbarActions class="app-navbar__actions" :is-mobile="isMobile" />
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '../../stores/global-store'
import AppNavbarActions from './components/AppNavbarActions.vue'
import VuesticLogo from '../VuesticLogo.vue'

defineProps({
  isMobile: { type: Boolean, default: false },
})

const GlobalStore = useGlobalStore()

const { isSidebarMinimized } = storeToRefs(GlobalStore)
</script>

<style lang="scss" scoped>
.bg-primary-custom {
  background-color: #4d1512 !important;
}

.va-navbar {
  z-index: 2;
  padding-left: 2rem;
  padding-right: 2rem;

  @media screen and (min-width: 950px) {
    padding-left: 4rem; /* px-16 equivalent */
    padding-right: 4rem; /* px-16 equivalent */
  }

  @media screen and (max-width: 950px) {
    padding-left: 0.5rem; /* px-2 equivalent */
    padding-right: 0.5rem; /* px-2 equivalent */
  }
}

.left {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}
</style>
