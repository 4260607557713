<template>
  <VaLayout :top="{ fixed: true, order: 2 }" @leftOverlayClick="isSidebarMinimized = true">
    <template #top>
      <AppNavbar :is-mobile="isMobile" />
    </template>

    <template #content>
      <!-- <div :class="{ minimized: isSidebarMinimized }" class="app-layout__sidebar-wrapper">
        <div v-if="isFullScreenSidebar" class="flex justify-end">
          <VaButton class="px-4 py-4" icon="md_close" preset="plain" @click="onCloseSidebarButtonClick" />
        </div>
      </div> -->
      <!-- <AppLayoutNavigation v-if="!isMobile" class="p-4" /> -->
      <main class="p-0 pt-0">
        <article>
          <RouterView :key="$route.fullPath" />
        </article>
      </main>
      <!-- Footer -->
      <footer class="auth-layout__footer bg-red-600 text-white">
        <div class="auth-layout__footer-content flex flex-wrap justify-between items-start pb-4 border-b border-red-600">
          <!-- Footer Logo and Description -->
          <div class="auth-layout__footer-logo flex-1 text-left p-2 md:max-w-1/2 sm:max-w-full sm:text-left">
            <div>
              <img src="@/assets/svgs/w_footerlogo.svg" alt="Yifeenah" class="h-10 mb-4 mx-auto sm:mx-auto" />
            </div>
            <div>
              <p class="text-sm">Looking to buy or sell your jet ski? We’re here to make it happen... 🎉 Yifeenah is a premier online platform
          specializing in the sale of jet skis. Our website connects buyers and sellers, offering a wide range of jet
          ski models to suit every preference and budget. 🏄‍♂️</p>
            </div>
          </div>

          <!-- Quick Links -->
          <div class="auth-layout__footer-links flex-1 p-2 md:max-w-1/4 sm:max-w-full sm:text-left">
            <h3 class="font-bold mb-2 text-lg">Quick Links</h3>
            <ul class="list-none sm:pl-0">
              <!-- <li>
                <RouterLink to="/buy-sell" class="text-white no-underline hover:underline">Buy & Sell</RouterLink>
              </li>
              <li>
                <RouterLink to="/how-it-works" class="text-white no-underline hover:underline">How it works</RouterLink>
              </li> -->
              <li>
                <RouterLink to="/faq" class="text-white no-underline hover:underline">FAQ</RouterLink>
              </li>
              <li>
                <RouterLink to="/terms" class="text-white no-underline hover:underline">Terms & Conditions</RouterLink>
              </li>
              <li>
                <RouterLink to="/privacy" class="text-white no-underline hover:underline">Privacy Policy</RouterLink>
              </li>
            </ul>
          </div>

          <!-- Contact Section -->
          <div class="auth-layout__footer-contact flex-1 p-2 md:max-w-1/4 sm:max-w-full sm:text-center">
            <h3 class="font-bold mb-4 text-lg">Can we help you?</h3>
            <div class="flex items-center justify-start mb-4 mt-4 sm:justify-center">
              <a href="tel:+97124442535" class="flex items-center text-white no-underline hover:underline">
                <img src="@/assets/svgs/phone.svg" alt="Call Yifeenah" class="h-5 w-5 mr-2" />
                <span>+971 2 444 2535</span>
              </a>
            </div>
            <div class="flex items-center justify-start sm:justify-center">
              <a href="mailto:info@yifeenah.com" class="flex items-center text-white no-underline hover:underline">
                <img src="@/assets/svgs/mail.svg" alt="Email Yifeenah" class="h-5 w-5 mr-2" />
                <span>info@yifeenah.com</span>
              </a>
            </div>
          </div>
        </div>

        <!-- Footer Bottom -->
        <div class="auth-layout__footer-bottom text-center pt-4 pb-4">
          <p class="text-sm">© 2025 Yifeenah</p>
        </div>
      </footer>
    </template>
  </VaLayout>

</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import VuesticLogo from '../components/VuesticLogo.vue'
import { authService } from './../services/auth'

import { onBeforeUnmount, onMounted, ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { onBeforeRouteUpdate } from 'vue-router'
import { useBreakpoint } from 'vuestic-ui'

import { useGlobalStore } from '../stores/global-store'

import AppLayoutNavigation from '../components/app-layout-navigation/AppLayoutNavigation.vue'
import AppNavbar from '../components/navbar/AppNavbar.vue'
import AppSidebar from '../components/sidebar/AppSidebar.vue'

const GlobalStore = useGlobalStore()

const breakpoints = useBreakpoint()

const sidebarWidth = ref('16rem')
const sidebarMinimizedWidth = ref(undefined)

const isMobile = ref(false)
const isTablet = ref(false)
const { isSidebarMinimized } = storeToRefs(GlobalStore)

const onResize = () => {
  isSidebarMinimized.value = breakpoints.mdDown
  isMobile.value = breakpoints.smDown
  isTablet.value = breakpoints.mdDown
  sidebarMinimizedWidth.value = isMobile.value ? '0' : '4.5rem'
  sidebarWidth.value = isTablet.value ? '100%' : '16rem'
}

onMounted(() => {
  window.addEventListener('resize', onResize)
  onResize()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

onBeforeRouteUpdate(() => {
  if (breakpoints.mdDown) {
    // Collapse sidebar after route change for Mobile
    isSidebarMinimized.value = true
  }
})

const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)

const onCloseSidebarButtonClick = () => {
  isSidebarMinimized.value = true
}


// Reactive state to track login status
const isLoggedIn = ref(authService.isAuthenticated) // Change this to `true` to simulate a logged-in user

const router = useRouter()

// Function to toggle login status (for testing purposes)
const toggleLoginStatus = () => {
  isLoggedIn.value = !isLoggedIn.value
}
</script>

<style lang="scss" scoped>
/* // Prevent icon jump on animation */
.va-sidebar {
  width: unset !important;
  min-width: unset !important;
}

.auth-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f8f8;
}

.auth-layout__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background-color: #4d1512;
}

.auth-layout__actions a {
  transition: color 0.2s ease-in-out;
}

.auth-layout__actions a:hover {
  color: #f5a623;
}

.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  color: #4d1512;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
}

.user-icon:hover {
  transform: scale(1.1);
}

.auth-layout__logo {
  height: 75px;
}

.auth-layout__language-switcher {
  display: flex;
  align-items: center;
}

.auth-layout__content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.auth-layout__footer {
  background-color: #f94c44;
  color: white;

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 50px 120px; /* Default for large screens */
    gap: 10px; /* Default for large screens */
    border-bottom: 1px solid #f94c44;

    @media (max-width: 768px) {
      padding: 20px 40px; /* Reduced padding for mobile */
      gap: 20px; /* Reduced gap for mobile */
      width: 100%; /* Full width for mobile */
      justify-content: center; /* Center content for better alignment */
    }
  }

  &-logo {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    text-align: left;
    padding: 10px;

    @media (max-width: 768px) {
      max-width: 100%;
      text-align: center;
    }

    img {
      margin: 0 auto;
    }

    p {
      font-size: 0.875rem; /* Smaller font size for mobile */
    }
  }

  &-links {
    flex: 1;
    max-width: 25%;
    padding: 10px;

    @media (max-width: 768px) {
      max-width: 100%;
      text-align: center;
    }

    h3 {
      margin-bottom: 8px;
      font-weight: bold;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        margin-bottom: 4px;

        a {
          color: white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-contact {
    flex: 1;
    max-width: 25%;
    text-align: left;
    padding: 10px;

    @media (max-width: 768px) {
      max-width: 100%;
      text-align: center;
    }

    h3 {
      margin-bottom: 8px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 768px) {
        justify-content: center;
      }

      img {
        margin-right: 8px;
      }
    }
  }

  &-bottom {
    text-align: center;
    padding: 20px;

    @media (max-width: 768px) {
      padding: 10px;
    }

    p {
      font-size: 0.875rem; /* Smaller font size for mobile */
    }
  }
}

.auth-layout__footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 50px 120px;
  gap: 10px;
  border-bottom: 1px solid #f94c44;
}
// .auth-layout__footer-content for mobile screens
@media (max-width: 768px) {
  .auth-layout__footer-content {
    padding: 10px 10px;
    gap: 20px;
    width: 100%;
    justify-content: center;
  }
}

.auth-layout__footer-logo {
  display: flex;
  max-width: 50%;
  justify-content: left;
  flex-wrap: wrap;
  padding: 10px;
  gap: 25px;
  text-align: left;
  align-items: start;
}

.auth-layout__footer-logo-img {
  height: 40px;
  margin-bottom: 16px;

}

.auth-layout__footer-links {
  flex: 1;
  max-width: 25%;
  padding: 10px;
}

.auth-layout__footer-links h3 {
  margin-bottom: 8px;
  font-weight: bold;
}

.auth-layout__footer-links ul {
  list-style-type: disc;
  padding-left: 20px;
}

.auth-layout__footer-links a {
  display: block;
  margin-bottom: 4px;
  color: white;
  text-decoration: none;
}

.auth-layout__footer-contact {
  flex: 1;
  max-width: 25%;
  text-align: left;
  padding: 10px;
}

.auth-layout__footer-contact h3 {
  margin-bottom: 8px;
}

.auth-layout__footer-bottom {
  text-align: center;
  padding-horizontal: 120px;
  background-color: #E93E36;
}

.auth-layout__footer-logo p {
  text-align: left; /* Align text to the left */
  text-justify: inter-word; /* Justify the text */
  font-size: 0.875rem; /* Ensure smaller font size for mobile */
  line-height: 1.5; /* Add line height for better readability */
}

@media (max-width: 768px) {

  .auth-layout__footer-logo,
  .auth-layout__footer-links,
  .auth-layout__footer-contact {
    max-width: 100%;
    text-align: center;
  }

  .auth-layout__footer-logo {
    margin-bottom: 16px;
  }

  .auth-layout__footer-contact {
    text-align: center;
  }
}
</style>
