import { title } from "process";
import { text } from "stream/consumers";
import { VaButton } from "vuestic-ui";

export default {
  presets: {
    light: {
      backgroundPrimary: '#F4F6F8',
      backgroundSecondary: '#FFFFFF',
      backgroundCardPrimary: '#F7F9F9',
      backgroundCardSecondary: '#ECFDE6',
      success: '#228200',
      info: '#158DE3',
      danger: '#E42222',
      warning: '#FFD43A',
      primary: '#282F3A',
      secondary: '#F4F6F8',
      accent: '#158DE3',
      button: '#158DE3',
      text: '#2D3748',
      title: '#2D3748',
      subtitle: '#2D3748',
      label: '#2D3748',
      textSecondary: '#4D1512',
      scbg1: '#FDE4E4',
    },
    dark: {
      backgroundCardPrimary: '#4D1512',
      backgroundCardSecondary: '#4D1512',
    },
  },
}
