import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'
import { authService } from '../services/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' },
  },
  {
    name: 'home',
    path: '/',
    component: AppLayout,
    children: [
      {
        name: 'home',
        path: '',
        component: () => import('../pages/home/HomePage.vue'),
        meta: { requiresAuth: false },
      },
      {
        name: 'test',
        path: 'test',
        component: () => import('../pages/home/TestPage.vue'),
        meta: { requiresAuth: false },
      },
      {
        name: 'ads',
        path: '/ads',
        component: () => import('../pages/ads/AdsPage.vue'),
        meta: { requiresAuth: false },
      },
      {
        name: 'myads',
        path: '/myads',
        component: () => import('../pages/myads/MyAdsPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'favorites',
        path: '/favorites',
        component: () => import('../pages/favorites/FavoritesPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'adsdetail',
        path: '/ads/:id',
        component: () => import('../pages/adsdetail/AdsDetailPage.vue'),
        meta: { requiresAuth: false },
      },
      {
        name: 'profile',
        path: '/profile',
        component: () => import('../pages/profile/ProfilePage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: '404',
        path: '/404',
        component: () => import('../pages/404.vue'),
      },
      {
        name: 'terms',
        path: '/terms',
        component: () => import('../pages/Terms.vue'),
      },
      {
        name: 'privacy',
        path: '/privacy',
        component: () => import('../pages/Privacy.vue'),
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/FAQ.vue'),
      },
    ],
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    redirect: { name: '/' },
    children: [
      {
        name: 'reports',
        path: 'reports',
        component: () => import('../pages/admin/reports/ReportsView.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'aiimage',
        path: 'aiimage',
        component: () => import('../pages/admin/pages/AIimageprocess.vue'),
      },
      {
        name: 'analytics',
        path: 'analytics',
        component: () => import('../pages/admin/analytics/AnalyticsView.vue'),
      },
      {
        name: 'customers',
        path: 'customers',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      // {
      //   name: 'user_management',
      //   path: 'user_management',
      //   component: () => import('../pages/users/UsersPage.vue'),
      // },
      {
        name: 'roles_permissions',
        path: 'roles_permissions',
        component: () => import('../pages/404.vue'),
      },
      {
        name: 'coupons_discounts',
        path: 'coupons_discounts',
        component: () => import('../pages/404.vue'),
      },
      {
        name: 'reviews_feedback',
        path: 'reviews_feedback',
        component: () => import('../pages/404.vue'),
      },
      {
        name: 'invoices',
        path: 'invoices',
        component: () => import('../pages/404.vue'),
      },
      {
        name: 'social_manager',
        path: 'social_manager',
        component: () => import('../pages/social/SocialManagerPage.vue'),
      },
      {
        name: 'control_panel',
        path: 'control_panel',
        component: () => import('../pages/controlpanel/ControlPanelView.vue'),
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'brands',
        path: 'brands',
        component: () => import('../pages/brands/BrandsPage.vue'),
      },
      {
        name: 'categories',
        path: 'categories',
        component: () => import('../pages/categories/CategoriesPage.vue'),
      },
      {
        name: 'listings',
        path: 'listings',
        component: () => import('../pages/listings/ListingsPage.vue'),
      },
      {
        name: 'events',
        path: 'events',
        component: () => import('../pages/events/EventsPage.vue'),
      },
      {
        name: 'social',
        path: 'social',
        component: () => import('../pages/social/SocialManagerPage.vue'),
      },
      {
        name: 'subscriptions',
        path: 'subscriptions',
        component: () => import('../pages/subscriptions/SubscriptionsPage.vue'),
      },
      {
        name: 'communities',
        path: 'communities',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      // {
      //   name: 'faq',
      //   path: '/faq',
      //   component: () => import('../pages/faq/FaqPage.vue'),
      // },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: '/' },
      },
    ],
  },
  // {
  //   name: '404',
  //   path: '/404',
  //   component: () => import('../pages/404.vue'),
  // },

  {
    name: 'logout',
    path: '/logout',
    component: () => import('./../pages/logout.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

// 🛑 Global Navigation Guard: Prevents unauthorized users from accessing protected routes
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !authService.isAuthenticated()) {
    authService.logout() // Clears invalid tokens if any
    next({ name: 'home' }) // Redirect to login BEFORE loading any content
  } else {
    next()
  }
})

export default router
