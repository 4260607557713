export const authService = {
  getToken: () => localStorage.getItem('token'),

  saveToken: (token: string) => {
    localStorage.setItem('token', token)
  },

  isAuthenticated: () => {
    const token = localStorage.getItem('token')
    if (!token) return false

    try {
      const payload = JSON.parse(atob(token.split('.')[1])) // Decode JWT payload
      return payload.exp * 1000 > Date.now() // Check if token is expired
    } catch (e) {
      return false
    }
  },

  isAuthenticatedAsync: async () => {
    const token = localStorage.getItem('token')
    if (!token) return false

    try {
      const payload = JSON.parse(atob(token.split('.')[1])) // Decode JWT payload
      return payload.exp * 1000 > Date.now() // Check if token is expired
    } catch (e) {
      return false
    }
  },

  logout: () => {
    localStorage.removeItem('token')
  },
}
